import React from 'react'
import PropTypes from 'prop-types'
import { ButtonLink } from './Button'
import 'twin.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
} from '@fortawesome/pro-solid-svg-icons'

const Paginator = ({ urlPrefix, pageOneSlug, totalPages, currentPage }) => {
  if (totalPages > 1) {
    const maxDisplayedNumbers = 5

    const numberDiff = Math.floor(maxDisplayedNumbers / 2)

    let leadSlice =
      currentPage > numberDiff ? currentPage - (numberDiff + 1) : 0
    const endSlice =
      currentPage > numberDiff ? currentPage + numberDiff : maxDisplayedNumbers

    if (currentPage >= totalPages - 1 && totalPages >= maxDisplayedNumbers) {
      leadSlice = totalPages - maxDisplayedNumbers
    }

    const previousPage = currentPage - 1
    const nextPage = currentPage + 1

    const pageNumbers = [...Array(totalPages).keys()]
      .map(pageNumber => ++pageNumber)
      .slice(leadSlice, endSlice)

    return (
      <nav
        tw="pt-16 flex justify-center w-full"
        role="navigation"
        aria-label="Pagination Navigation"
      >
        <ul tw="flex space-x-2">
          {/* Fast Forward Button -- Takes you to page 1 */}
          <li>
            <ButtonLink
              small
              to={pageOneSlug || `${urlPrefix}1`}
              aria-label={`Goto Page 1`}
              disabled={currentPage === 1}
              link
              rel="nofollow"
            >
              <FontAwesomeIcon icon={faChevronDoubleLeft} />
            </ButtonLink>
          </li>

          {/* Previous button -- takes you to the previous page */}
          <li>
            <ButtonLink
              small
              to={
                previousPage === 1 && pageOneSlug
                  ? pageOneSlug
                  : `${urlPrefix}${currentPage - 1}`
              }
              aria-label={
                currentPage - 1 > 1
                  ? `Goto Page ${currentPage - 1}`
                  : `Goto Page 1`
              }
              disabled={currentPage === 1}
              link
              rel="nofollow"
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </ButtonLink>
          </li>

          {/* Numbers */}
          {pageNumbers.map(pageNumber => (
            <li key={`page-link-${pageNumber}`}>
              <ButtonLink
                small
                to={
                  pageNumber === 1 && pageOneSlug
                    ? pageOneSlug
                    : `${urlPrefix}${pageNumber}`
                }
                aria-label={
                  pageNumber === currentPage
                    ? `Current Page, Page ${pageNumber}`
                    : `Goto Page ${pageNumber}`
                }
                aria-current={pageNumber === currentPage}
                secondary={pageNumber !== currentPage}
              >
                {pageNumber}
              </ButtonLink>
            </li>
          ))}

          {/* Next Button */}
          <li>
            <ButtonLink
              small
              to={`${urlPrefix}${
                nextPage > totalPages ? currentPage : nextPage
              }`}
              aria-label={
                nextPage > totalPages
                  ? `Goto Page ${currentPage}`
                  : `Goto Page ${currentPage + 1}`
              }
              disabled={currentPage === totalPages}
              link
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </ButtonLink>
          </li>

          {/* Last Page Button */}
          <li>
            <ButtonLink
              small
              to={`${urlPrefix}${totalPages}`}
              aria-label={`Goto Page ${totalPages}`}
              disabled={currentPage >= totalPages}
              link
            >
              <FontAwesomeIcon icon={faChevronDoubleRight} />
            </ButtonLink>
          </li>
        </ul>
      </nav>
    )
  }

  return null
}
Paginator.defaultProps = {
  pageOneSlug: null,
  totalPages: 1,
}

Paginator.propTypes = {
  urlString: PropTypes.string.isRequired,
  pageOneSlug: PropTypes.string,
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
}

export default Paginator
